import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {of} from 'rxjs';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {

  @Input() control: FormControl = new FormControl();
  @Input() isDisplayError = false;
  @Input() fieldName = 'Field';

  ngOnInit() {

  }

  get showError(): string {
    if (this.control.invalid) {

      if (this.control.errors.pattern) {
        return this.fieldName + ' is not valid';
      }
      if (this.control.errors.match) {
        return this.fieldName + ' does not match';
      }
      if (this.control.errors.required) {
        return this.fieldName + ' is required';
      }
      if (this.control.errors.phone) {
        return this.fieldName + ' has invalid format it must be (+12345678901)';
      }
      if (this.control.errors.minlength) {
        return this.fieldName + ' must be have a length of ' + this.control.errors.minlength.requiredLength;
      }
      if (this.control.errors.maxlength) {
        return this.fieldName + ' must be have a length less than ' + this.control.errors.maxlength.requiredLength;
      }
      if (this.control.errors.email) {
        return this.fieldName + ' has invalid format it must be (test@gmail.com)';
      }
      if (this.control.errors.password) {
        return this.fieldName + ' must contain at least 8 characters, a digit, an upper case , a lower case and a special character';
      }
      if (this.control.errors.greater) {
        return this.fieldName + ' must be greater than ' + this.control.errors.value;
      }

      if (this.control.errors.less) {
        return this.fieldName + ' must be less than ' + this.control.errors.value;
      }
    }
    return '';
  }
}
