import { Component, OnInit, Input } from '@angular/core';
import { MerchantDetail } from '../../../../models/merchantDetail';
@Component({
  selector: 'app-merchant-basic-info',
  templateUrl: './merchant-basic-info.component.html',
  styleUrls: ['./merchant-basic-info.component.scss'],
})
export class MerchantBasicInfoComponent implements OnInit {
  @Input() merchantDetail: MerchantDetail = new MerchantDetail();
  constructor() {}

  ngOnInit(): void {

  }
}
