import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';

@Pipe({
  name: 'appCurrency'
})
export class AppCurrencyPipe implements PipeTransform {

  transform(value: number): unknown {
    if (value == null) {
      value = 0;
    }
    return formatCurrency(
      value,
      'en',
      getCurrencySymbol('$', 'wide'),
      '$',
    );
  }

}
