import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() src;
  @Input() name;
  constructor() { }

  ngOnInit(): void {
    this.src="http://ec2-18-220-251-221.us-east-2.compute.amazonaws.com"+this.src
  }

}
