import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControlDirective} from './form-control.directive';


@NgModule({
  declarations: [FormControlDirective],
  exports: [FormControlDirective],
  imports: [
    CommonModule
  ]
})
export class CustomDirectiveModule {
}
