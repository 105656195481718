export class Merchant {
  index: number;
  fullName: string;
  name: string;
  dateOfBirth: string;
  email: string;
  phone: string;
  accessToken: string;
  state: string;
  businessType: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  userName: string;
  address: string;
  id: string;
  isEnabled: boolean;
  isApproved: boolean;
  userId: string;
  isVerified: boolean;
  image: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  taxId: string;
  socialNumber: string;
  socialNumberImage: string;
  role: string;
  rccmImage: string;
  identificationImage: string;
  ntnNumber: string;
  identificationNumber: string;
  rccm: string;
  createdDate: string;
  stripeAccountId: string;

  constructor() {
    this.confirmPassword = this.fullName = this.email = this.phone = this.password = this.userName = '';
  }

}
