import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CartService} from '../../service/cart.service';
import {CheckOutDialogComponent} from '../check-out-dialog/check-out-dialog.component';
import {Order} from '../../../../models/order';

@Component({
  selector: 'app-cart-info-dialog',
  templateUrl: './cart-info-dialog.component.html',
  styleUrls: ['./cart-info-dialog.component.scss']
})
export class CartInfoDialogComponent implements OnInit {

  orderCompleted: (order: Order) => void;

  constructor(
    private cartService: CartService,
    private modalService: NgbModal,
    private dialogService: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogService.close();
  }

  get cartItems() {
    return this.cartService.getCartItems();
  }

  get total() {
    return this.cartService.getTotal();
  }

  get canNotCheckOut() {
    return this.cartItems.length === 0;
  }

  showCheckOutDialog() {
    const modal = this.modalService.open(CheckOutDialogComponent, {
      centered: true,
      size: 'sm'
    });
    const instance = modal.componentInstance as CheckOutDialogComponent;

    instance.orderCompleted = (order => {
    });
  }
}
