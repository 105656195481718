import { Component, Input, OnInit } from '@angular/core';

import {ConfigurationService} from '../../services/configuration.service';
@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  constructor(
    private configurationService: ConfigurationService
  ) { }
  @Input() height = 256;

  @Input() src: string;
  @Input() class: any;
  ngOnInit(): void {
    if (this.src.startsWith('htt')) {
      this.src = this.src;
    } else {
      this.src = this.configurationService.baseUrl + this.src;
    }
  }

}
