import {Component} from '@angular/core';
import {AlertDialog, AlertMessage, AlertService, MessageSeverity} from './services/alert.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DialogComponent} from './dialog/dialog/dialog.component';


declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private alertService: AlertService,
              private modalService: NgbModal) {
    this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
    this.alertService.getMessageEvent().subscribe(message => this.showToast(message, false));
    this.alertService.getStickyMessageEvent().subscribe(message => this.showToast(message, true));
  }


  title = 'Herb Tech';
  private modal: NgbModalRef;

  showDialog(dialog: AlertDialog) {
    if (dialog === undefined) {
      this.modal.close();
      return;
    }
    dialog.okLabel = dialog.okLabel || 'OK';
    dialog.cancelLabel = dialog.cancelLabel || 'Cancel';
    this.modal = this.modalService.open(DialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'sm'
    });
    const instance = this.modal.componentInstance as DialogComponent;
    instance.initDialog(dialog);
    instance.NoClick = () => {
      this.modal.close();
    };
  }

  showToast(message: AlertMessage, isSticky: boolean) {
    let delay = 0;
    if (message === undefined) {
      $.notifyClose();
      return;
    }
    if (message.severity === MessageSeverity.wait) {
      delay = 0;
    } else {
      delay = 3000;
    }

    // const toastOptions: ToastOptions = {
    //   title: message.summary,
    //   msg: message.detail,
    //   showClose: true,
    //   timeout: delay,
    // };
    // this.showMessageBySeverity(message.severity, toastOptions);
    $.notifyDefaults({
      allow_dismiss: true,
      z_index: 1051
    });

    $.notify({
      icon: 'notifications',
      message: message.detail,
      title: message.summary,
    }, {
      type: this.getClassBySeverity(message.severity),
      delay,
      z_index: 1051,
      placement: {
        from: 'top',
        align: 'right'
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss" style="margin:5px">×</button>' +
        '<span data-notify="icon"></span> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  getClassBySeverity(severity: MessageSeverity): string {
    switch (severity) {
      case MessageSeverity.success:
        return 'success';
      case MessageSeverity.error:
        return 'danger';
      case MessageSeverity.warn:
        return 'warn';
      case MessageSeverity.info:
        return 'info';
      case MessageSeverity.wait:
        return 'info';
    }
    return '';
  }
}
