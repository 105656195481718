import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-custom-form-control',
  templateUrl: './custom-form-control.component.html',
  styleUrls: ['./custom-form-control.component.scss']
})
export class CustomFormControlComponent implements OnInit {

  @Input() inputType = 'text';
  @Input() placeholder = 'Enter Text';
  @Input() inputFormControl: FormControl = new FormControl();
  @Input() isDisplayError = false;
  @Input() fieldName = 'Field';
  constructor() { }

  ngOnInit(): void {
  }

}
