import {CartItem} from './cartItem';

export class Cart {
  constructor() {
    this.products = [];
  }

  refNo: string;
  email: string;
  phoneNumber: string;
  otp: string;
  merchantId: string;
  deviceId: string;
  total: number;
  products: CartItem[];
}