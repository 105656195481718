import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {AppCurrencyPipe} from './app-currency.pipe';
import {CustomDatePipe} from './custom-date.pipe';
import {PaymentTypePipe} from './payment-type.pipe';
import {OrderStatusPipe} from './order-status.pipe';
import {AnimationPipe} from './animation-pipe.pipe';
import { BenifitsAnimation } from './benifits-animation.pipe';

@NgModule({
  declarations: [AppCurrencyPipe, CustomDatePipe, PaymentTypePipe, OrderStatusPipe, AnimationPipe, BenifitsAnimation],
  exports: [AppCurrencyPipe, CustomDatePipe, PaymentTypePipe, OrderStatusPipe, AnimationPipe, BenifitsAnimation],
  imports: [
    CommonModule
  ],
  providers: [DatePipe]
})
export class CustomPipeModule {
}
