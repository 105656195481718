import {Injectable} from '@angular/core';
import {EndpointFactoryService} from '../../../services/endpoint-factory.service';
import {Merchant} from '../../../models/merchant';
import {Category} from '../../../models/category';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ResponseModel} from '../../../models/response.model';
import {Cart} from '../../../models/cart';
import {Order} from '../../../models/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends EndpointFactoryService {
  private readonly baseUrl = this.configurations.baseUrl + '/api/v1/order';
  private readonly otpUrl = this.baseUrl + '/otp';
  private readonly detailUrl = this.configurations.baseUrl + '/api/v1/public/order';

  generateOtp(refNo: string, email: string, phoneNumber: string, merchantId: string) {
    return this.http.post(this.otpUrl, {
      refNo,
      email,
      phoneNumber,
      merchantId
    }).pipe(map((response) => response as ResponseModel));
  }

  createOrder(cart: Cart) {
    return this.http.post(this.baseUrl, cart).pipe(map((response) => response as Order));
  }

  getOrderDetail(id: string) {
    return this.http.get(this.detailUrl + '/' + id, {
    }).pipe(map((response) => response as Order));
  }
  getOrders(
    email: string,
    phoneNumber: string,
    page: number, pageSize: number, search: string, orderBy: string, direction: string) {
    const params = new HttpParams({
      encoder: this.encoderService
    }).set('page', page + '').set('pageSize', pageSize + '')
      .set('email', email + '').set('phoneNumber', phoneNumber + '')
      .set('search', search + '').set('isDescending', direction === 'desc' ? 'true' : 'false').set('orderBy', orderBy + '');
    return this.http.get(this.baseUrl, {
      params
    }).pipe(map((response) => response as ResponseModel));
  }
}
