<div class="item">
  <div class="product-image weed-hover weed-ani py-3">
    <img
      class="img-fluid flower-w-h mb-2"
      [src]="src"

    />
    <span class="font-mont-18 uppercase">{{name}}</span>
  </div>
</div>
