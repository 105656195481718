<div class="modal-header">
  <h5 class="modal-title mod-head text-center">
    My Cart
  </h5>
  <button (click)="close()" type="button" class="close">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="cartItems.length == 0">
    <div class="text-center font-weight-bold">No Items Added in the Cart</div>
  </div>
  <div *ngFor="let cartItem of cartItems">
    <app-cart-item [cartItem]="cartItem"></app-cart-item>
  </div>
</div>
<div class="modal-footer">
  <div class="row width-100">
    <div class="col-sm-12">
      <div class="card my-3 card-own">
        <div class="card-body px-4 py-4">
          <div class="row">
            <div class="col-6">
              <h6>Tax</h6>
              <h6>Sub Total</h6>
              <h6>Net Total</h6>
            </div>
            <div class="col-6">
              <span class="float-right text-gray">{{0 | appCurrency}}</span>
              <br>
              <span class="float-right text-gray">{{total | appCurrency}}</span>
            </div>
            <button (click)="showCheckOutDialog()" [disabled]="canNotCheckOut" class="btn btn-success btn-lg btn-block rounded-pill text-center" data-toggle="modal" data-target="#Cart">
              <span class="">Check Out</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


