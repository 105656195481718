import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-see-more',
  templateUrl: './see-more.component.html',
  styleUrls: ['./see-more.component.scss']
})
export class SeeMoreComponent implements OnInit {
  sliceOptions = {
    start: 0,
    end: 100,
    default: 100
  };

  @Input()
  description = '';

  constructor() {
  }

  ngOnInit(): void {
  }

  onToggleText(): void {
    this.sliceOptions.end = this.sliceOptions.end ? undefined : this.sliceOptions.default;
  }
}
