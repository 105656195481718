<div class="row" *ngIf="cartItem">
    <div class="col-12">
        <div class="card my-3 card-own">
            <div class="card-body row">
                <div class="col-3 p-0 m-auto">
                    <div class="text-center my-xl-0 my-lg-0">
                        <app-image class="img-lg-height" [src]="cartItem.product.image"></app-image>
                    </div>
                </div>
                <div class="col-7 py-3  pl-4 pr-0">
                    <div class="row">
                        <div class="col-12 pr-0">
                            <h6 class="float-left">{{cartItem.product.name}}</h6>
                            <span class="sm-font-size font-size-12 position-relative pos-rel text-gray">({{cartItem.pricing.weight}}{{cartItem.pricing.weightUnit}}
                )</span>
                        </div>
                        <div class="col-12 float-left">
                            <div class="image-height">
                                <a (click)="decreaseQuantity()" class="sub btn btn-outline-success sm-font-size border-0 background-gray-green text-center modal-font px-2 br-25">
                                    <i class="fa fa-minus"></i>
                                </a>
                                <input type="text" [value]="cartItem.quantity" readonly class="sm-font-size px-2 mod-input">
                                <a (click)="increaseQuantity()" class="add btn btn-outline-success sm-font-size border-0 background-gray-green text-center modal-font px-2 br-25">
                                    <i class="fas fa-plus"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col-12 pt-3">
                            <h6 class="sm-font-size m-0 text-success">{{cartItem.total | appCurrency}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-2 p-0 pl-2 m-auto text-center">
                    <button (click)="removeFromCart()" class="btn btn-outline-danger border-0 background-gray-red my-4 br-25">
            <i class="fas fa-trash"></i>
          </button>
                </div>
            </div>
        </div>
    </div>
</div>
