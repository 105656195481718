import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent {
  message = '';
  viewAllText = '';
  viewAll: () => void;
  createAnother: () => void;
  createAnotherText = 'Create Another';

  constructor(private dialogService: NgbActiveModal) {
  }

  close() {
    this.dialogService.close();
  }
}
