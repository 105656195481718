import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QrpagesRoutingModule } from './qrpages-routing.module';
import { QrpageManagmentComponent } from './components/qrpage-managment/qrpage-managment.component';
import {WebcamModule} from 'ngx-webcam';
import {FormsModule} from '@angular/forms';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
@NgModule({
  declarations: [QrpageManagmentComponent],
  imports: [
    CommonModule,
    QrpagesRoutingModule,
    WebcamModule,
    FormsModule,
    ZXingScannerModule


  ],

})
export class QrpagesModule { }
