<label class="ct-label" [class.full-width]="isFullWidth">
<form>
  <div class="input-group no-border">
    <input id="searchInput" class="form-control" #searchInput type="search"
           (keydown.esc)="searchInput.value=''; onSearchChange()" (keydown.enter)="onSearchChange()"
           [placeholder]="placeholder"
           [formControl]="searchControl"
           style="padding-right:35px;">

    <div class="input-group-append">
      <div class="input-group-text"><i class="fa fas fa-search"></i></div>
    </div>
  </div>
</form>
</label>
