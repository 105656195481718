
  <div class="card full-page">
  <div class="card-header text-center shadow">
      <h6>
        Scan
      </h6>
  </div>
  <div class="card-body  text-center">
    <img src="../../../../../assets/img/logo-icon.png" alt="logo icon" height="100px" width="100px">
    <div class="row">

      <div class="p-2 ml-auto mr-auto sample-heading">
       <div class="overflow-hidden width-150">
        <div class="size-200 heading">
          <zxing-scanner (scanSuccess)='onCodeResult($event)' ></zxing-scanner>
        </div>
       </div>
      </div>


    </div>
  </div>
</div>


