import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CartService} from '../../service/cart.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidator} from '../../../../validators/customValidator';
import {OrderService} from '../../service/order.service';
import {GuidGenerator} from '../../../../helpers/guidGenerator';
import {Order} from '../../../../models/order';
import {AlertService} from '../../../../services/alert.service';
import {NavigationService} from '../../../../services/navigation.service';

@Component({
  selector: 'app-check-out-dialog',
  templateUrl: './check-out-dialog.component.html',
  styleUrls: ['./check-out-dialog.component.scss']
})
export class CheckOutDialogComponent implements OnInit {

  orderCompleted: (order: Order) => void;
  merchantId: string;
  constructor(private dialogService: NgbActiveModal,
              private cartService: CartService,
              private navigation: NavigationService,
              private alertService: AlertService,
              private orderService: OrderService) {
  }

  step = 1;
  formGroup: FormGroup;
  otpFromGroup: FormGroup;
  emailControl: FormControl;
  // phoneControl: FormControl;
  firstNameControl: FormControl;
  lastNameControl: FormControl;
  //dobControl: FormControl;
 // otpControl: FormControl;
 // refNo = '';

  ngOnInit(): void {

  }

  get canNotCheckOut() {
    return this.cartService.getCartItems().length === 0;
  }
  initForm(merchantId: string) {
    this.merchantId = merchantId;
    this.emailControl = new FormControl('', [Validators.required, Validators.email]);
    // this.phoneControl = new FormControl('+1', [Validators.required, CustomValidator.Phone()]);
    //this.otpControl = new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
    this.firstNameControl = new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);
    this.lastNameControl = new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);
   // this.dobControl = new FormControl('2021-07-08', [Validators.required]);
    this.formGroup = new FormGroup({
      email: this.emailControl,
      // phone: this.phoneControl,
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
     // dob: this.dobControl
    });
    // this.otpFromGroup = new FormGroup({
    //   otp: this.otpControl
    // });
  }

  close() {
    this.dialogService.close();
  }

  get total() {
    return this.cartService.getTotal();
  }

  get tax() {
    return this.cartService.getTax();
  }

  get taxAmount() {
    return this.cartService.getTaxAmount();
  }

  get netAmount() {
    return this.cartService.getNetAmount();
  }

  // sendOtp() {
  //  // this.otpControl.reset();
  //   //this.refNo = GuidGenerator.newGuid().substr(0, 10);
  //   if (this.formGroup.valid) {
  //     this.alertService.startLoadingMessage();
  //     this.orderService.generateOtp(this.refNo, this.emailControl.value, this.phoneControl.value, this.merchantId).subscribe(result => {
  //       this.alertService.stopLoadingMessage();
  //       if (result.success) {
  //         this.step = 2;
  //       }
  //     });
  //   }

  // }

  verifyOtp() {
    if (this.formGroup.valid) {

      this.alertService.startLoadingMessage();
      const cart = {
        total: this.cartService.getTotal(),
        deviceId: null,
        email: this.emailControl.value,
        merchantId: this.cartService.getMerchantId(),
        products: this.cartService.getCartItems(),
        otp: "",
        phoneNumber: "+00000000000",
        refNo: "",
        firstName: this.firstNameControl.value,
        lastName: this.lastNameControl.value,
        dob: Date.now
      };
      this.orderService.createOrder(cart).subscribe(result => {
        this.alertService.stopLoadingMessage();
        this.close();
        this.orderCompleted(result);
      });
    }

  }


}
