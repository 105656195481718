export class Product {
  index: number;
  minPrice: number;
  variations: number;
  id: string;
  name: string;
  categoryId: number;
  category: string;
  image: string;
  createdDate: string;
  discount = 0;
  discountPercent = 0;
  isDiscounted = false;
  discountedPrice = 0;
}
