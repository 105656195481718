import {Product} from './product';
import {Pricing} from './pricing';

export class CartItem {
  name: string;
  constructor() {
  }
  productId: string;
  product: Product;
  quantity: number;
  priceId: number;
  price: number;
  total: number;
  pricing: Pricing;
  weight: string;
  weightUnit: string;
}
