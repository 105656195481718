import {Pipe, PipeTransform} from '@angular/core';
import {OrderStatus, PaymentType} from '../enums/payment-type.enum';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(value: number): string {
    switch (value as OrderStatus) {
      case OrderStatus.Cancelled:
        return '<span class="badge badge-danger">Cancelled</span>';
      case OrderStatus.Completed:
        return '<span class="badge badge-success">Completed</span>';
      case OrderStatus.Pending:
        return '<span class="badge badge-info">Pending</span>';
    }
    return '<span class="badge badge-light">N/A</span>';
  }

}
