<div class="card p-4 my-xl-3 my-lg-3 my-md-3 my-sm-3 mb-3 text-center card-own">

  <p *ngIf="product.isDiscounted" class="sm-font-size font-mont-14 text-center mb-0 dis-tag p-2">
    - {{ product.discountPercent + " %" }}
  </p>

  <div class="product-image mb-md-3 weedimg">
    <app-image [class]="'w-i-h'" [src]="product.image" alt="1st "></app-image>
  </div>
  <div class="position-relative mt-3">
    <h6 class="
        text-base-own
        mb-1
        text-center
        font-mont-20
        fm-sm-14
        text-capitalize
      ">
      {{ product.name }}
    </h6>
    <div class="sm-font-size font-mont-14 text-center my-2">
      <div class="f-16 font-weight-bold c-00" *ngIf="product.isDiscounted">
        <span class="f-12 c-a3">
          $
          <del>{{ product.minPrice }}</del>
        </span>
        $ {{ product.discountedPrice }}
      </div>
      <div class="f-16 font-weight-bold c-00" *ngIf="!product.isDiscounted">
        <span>
          $
          {{ product.minPrice }}
        </span>
      </div>
    </div>
    <a (click)="openDialog()" class="btn btn-outline-success border-0 shadow rounded-pill text-center  px-4"
      data-toggle="modal" data-target="#staticBackdrop">
      <i class="fa fa-shopping-basket"></i>
      <span class="sm-font-size pl-1">Add</span>
    </a>
  </div>
</div>
