import {Injectable} from '@angular/core';
import {Cart} from '../../../models/cart';
import {CartItem} from '../../../models/cartItem';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cart = new Cart();
  private merchantId: string;
  private tax = 0;

  addToCart(cartItem: CartItem) {
    let currentCartItem = this.cart.products.find(p => p.priceId === cartItem.priceId &&
      p.productId === cartItem.productId);
    if (currentCartItem) {
      currentCartItem.quantity += cartItem.quantity;
      currentCartItem.total = currentCartItem.quantity * currentCartItem.price;
    } else {
      currentCartItem = new CartItem();
      currentCartItem.quantity = cartItem.quantity;
      currentCartItem.price = cartItem.price;
      currentCartItem.total = currentCartItem.quantity * currentCartItem.price;
      currentCartItem.priceId = cartItem.priceId;
      currentCartItem.productId = cartItem.productId;
      currentCartItem.product = cartItem.product;
      currentCartItem.pricing = cartItem.pricing;
      this.cart.products.push(currentCartItem);
    }
    this.calculateTotal();
  }

  increaseQuantity(productId: string, priceId: number) {
    const currentCartItem = this.cart.products.find(p => p.priceId === priceId &&
      p.productId === productId);
    if (currentCartItem) {
      currentCartItem.quantity += 1;
      currentCartItem.total = currentCartItem.quantity * currentCartItem.price;
    }
    this.calculateTotal();
  }

  decreaseQuantity(productId: string, priceId: number) {
    const currentCartItem = this.cart.products.find(p => p.priceId === priceId &&
      p.productId === productId);
    if (currentCartItem) {
      if (currentCartItem.quantity < 2) {
        return;
      }
      currentCartItem.quantity -= 1;
      currentCartItem.total = currentCartItem.quantity * currentCartItem.price;
    }
    this.calculateTotal();
  }

  removeItemFromCart(productId: string, priceId: number) {
    const currentCartItem = this.cart.products.findIndex(p => p.priceId === priceId &&
      p.productId === productId);
    if (currentCartItem > -1) {
      this.cart.products.splice(currentCartItem, 1);
      this.calculateTotal();
    }
  }

  calculateTotal() {
    this.cart.total = this.cart.products.reduce((sum, current) => sum + current.total, 0);
  }

  getTotal() {
    return this.cart.total;
  }

  getTaxAmount() {
    return this.cart.total * (this.tax / 100);
  }

  getNetAmount() {
    return this.getTotal() + this.getTaxAmount();
  }

  getCartItems(): CartItem[] {
    return this.cart.products;
  }

  getMerchantId(): string {
    return this.merchantId;
  }

  setMerchantId(userId: string) {
    this.merchantId = userId;
  }

  clear() {
    this.cart = new Cart();
  }

  getTax() {
    return this.tax;
  }

  setTax(tax: number) {
    this.tax = tax;
  }
}
