import { Component, OnInit, Input } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductDetailInfoComponent } from '../product-detail-info/product-detail-info.component';
import { Product } from '../../../../models/product';
import { Merchant } from 'src/app/models/merchant';
@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
})
export class ProductInfoComponent implements OnInit {
  constructor(private dialogService: NgbModal) {}
  @Input() product: Product = new Product();
  @Input() merchant: Merchant;
  ngOnInit(): void {}

  openDialog() {
    const modal = this.dialogService.open(ProductDetailInfoComponent, {
      centered: true,
      size: 'lg'
    });
    const instance = modal.componentInstance as ProductDetailInfoComponent;
    instance.onInit(this.merchant, this.product);
  }
  
}
