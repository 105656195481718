import {Injectable} from '@angular/core';
import {ResponseModel} from '../../../models/response.model';
import {Merchant} from '../../../models/merchant';
import {map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {Category} from '../../../models/category';
import {Product} from '../../../models/product';
import {EndpointFactoryService} from '../../../services/endpoint-factory.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService extends EndpointFactoryService {
  private merchantDetails = '/api/v1/Public/merchant';
  private product = '/api/v1/Public/products';
  private productDetailUrl = '/api/v1/Public/product/detail';
  private subCategoryUrl = this.configurations.baseUrl + '/api/v1/SubCategory/dropDown';

  get merchantCategoryURL() {
    return this.configurations.baseUrl + this.merchantDetails;
  }

  get productsURL() {
    return this.configurations.baseUrl + this.product;
  }

  get getProductDetailUrl(){
    return this.configurations.baseUrl + this.productDetailUrl;
  }

  public getMerchantProfile(merchant: Merchant) {
    return this.http.get(this.merchantCategoryURL + '/' + merchant.id, {observe: 'response'});
  }

  public getProduct(merchant: Merchant, category: Category, page: number, pageSize: number, SubCategoryId: any  ) {
    if (SubCategoryId == null) {SubCategoryId = '';
  }
    const params = new HttpParams().set('page', page + '').set('pageSize', pageSize + '')
   
      .set('CategoryId', category.id.toString() + '').set('MerchantId', merchant.id.toString() + '')
      .set('SubCategoryId', SubCategoryId.toString() + '');
    return this.http.get(this.productsURL, {params}).pipe(map((response) => response as ResponseModel));
  }

  public getProductDetail(merchant: Merchant, product: Product){
    const params = new HttpParams().set('ProductId', product.id + '').set('MerchantId', merchant.id + '');
    return this.http.get(this.getProductDetailUrl, {params}).pipe(map((response) => response as ResponseModel));
  }
  public getSubCategory(merchant: Merchant) {
    return this.http.get(this.subCategoryUrl + '/' + merchant.id).pipe(map((response) => response as ResponseModel));
  }
}
