import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {Category} from '../../../../models/category';

@Component({
  selector: 'app-category-slider',
  templateUrl: './category-slider.component.html',
  styleUrls: ['./category-slider.component.scss'],
})
export class CategorySliderComponent implements OnInit {
  @Input() categories: Category[] = [];
  @Input() selectedCategory = new Category();
  @Output() onSelectChange = new EventEmitter<Category>();
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: true,
    navSpeed: 500,
    navText: ['<i class="fas fa fa-chevron-left"></i>', '<i class="fas fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 4,
      },
      740: {
        items: 6,
      },
      940: {
        items: 6,
      },
    },
  };

  constructor() {
  }

  ngOnInit(): void {
    let index=0;
    this.categories.forEach(item => {
      let categories: Category = new Category();
      categories=item;
      categories.index = index;
      this.categories.push(categories);
      index = index + 1;
      
    });
  }

  selectCategory(category: Category) {
    if (category.id == this.selectedCategory.id) {
      return;
    }
    this.selectedCategory = category;
    this.onSelectChange.emit(this.selectedCategory);
  }
}
