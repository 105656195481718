<div class="card my-3 text-center card-own">
    <div class="card-body">
        <div class="row">
            <div class="col-sm">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let category of categories">
                        <div (click)="selectCategory(category)" class=" product-image weed-hover p-1 my-2 {{category.index | orderAnimationCss}}" [class.selectedBackGround]='category.id == selectedCategory.id'>
                            <app-image [class]="'img-fluid flower-w-h pt-2 mb-2'" [src]='category.image'></app-image>
                            <span class="font-mont-slider uppercase limit">{{category.name}}</span>
                        </div>
                    </ng-template>

                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>