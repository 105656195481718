<div class="modal-header padding-10">
    <span class="modal-title font-weight-bold">{{getType()}}</span>
</div>
<div class="modal-body padding-10">
    <div class="row">
        <div class="col-sm">
            <p>{{content}}</p>
        </div>
    </div>
</div>
<div class="modal-footer padding-10">
    <button class="btn btn-primary btn-round btn-md font-weight-bold" (click)="OkClick()">{{ labelOk }}</button> &nbsp;
    <a class="text-danger cursor font-weight-bold" (click)="close()">{{ labelNo }}</a>
</div>