import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Merchant} from '../../../../models/merchant';
import {Product} from '../../../../models/product';
import {ServiceService} from '../../service/service.service';
import {ProductDetail} from '../../../../models/productDetail';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Pricing} from '../../../../models/pricing';
import {CartService} from '../../service/cart.service';
import {AlertService} from '../../../../services/alert.service';

@Component({
  selector: 'app-product-detail-info',
  templateUrl: './product-detail-info.component.html',
  styleUrls: ['./product-detail-info.component.scss'],
})
export class ProductDetailInfoComponent {
  totalPrice = 0;
  merchant: Merchant = new Merchant();
  product: Product = new Product();
  productDetail: ProductDetail = new ProductDetail();
  weightControl: FormControl;
  quantityControl: FormControl;
  productGroup: FormGroup;
  enableView = false;
  currentPricing: Pricing;

  constructor(
    private dialogService: NgbActiveModal,
    private homeService: ServiceService,
    private alertService: AlertService,
    private cartService: CartService,
  ) {
  }

  initForm() {
    if (this.productDetail.prices.length > 0) {
      this.weightControl = new FormControl(this.productDetail.prices[0], [
        Validators.required,
      ]);
    } else {
      this.weightControl = new FormControl('', [Validators.required]);
    }

    this.quantityControl = new FormControl(0, [
      Validators.required,
      Validators.min(1),
      Validators.max(99),
    ]);
    this.quantityControl.setValue(1);
    this.productGroup = new FormGroup({
      wight: this.weightControl,
      quantity: this.quantityControl,
    });
  }

  onInit(merchant: Merchant, product: Product) {
    this.merchant = merchant;
    this.product = product;
    this.loadData();
  }

  loadData() {
    this.homeService
      .getProductDetail(this.merchant, this.product)
      .subscribe((result) => {

        if (result.data) {
          this.productDetail = result.data as ProductDetail;
          let index = 0;
          this.productDetail.benefits.forEach(item => {
            this.productDetail.benefits[index].index = index;
            index = index + 1;
          });
          this.initForm();
          if (this.productDetail.prices && this.productDetail.prices.length > 0) {
            this.selectWeight(this.productDetail.prices[0]);
          }
          this.enableView = true;
        }
      });
  }

  close() {
    this.dialogService.close();
  }

  selectWeight(price: Pricing) {
    this.weightControl.setValue(price);
    this.currentPricing = price;
    const element = this.weightControl.value as Pricing;
    this.totalPrice = element.price * this.quantityControl.value;
  }

  increaseQuantity() {
    this.quantityControl.setValue(this.quantityControl.value + 1);
    const element = this.weightControl.value as Pricing;
    this.totalPrice = element.price * this.quantityControl.value;
  }

  decreaseQuantity() {
    if (+this.quantityControl.value < 0) {
      return;
    }
    this.quantityControl.setValue(this.quantityControl.value - 1);
    const element = this.weightControl.value as Pricing;
    this.totalPrice = element.price * this.quantityControl.value;
  }

  addToCart() {
    if (this.quantityControl.value === 0) {
      this.alertService.showErrorMessage('Cannot add item with quantity 0');
      return;
    }
    const element = this.weightControl.value as Pricing;
    this.cartService.addToCart({
      quantity: +this.quantityControl.value,
      price: element.price,
      total: this.totalPrice,
      productId: this.product.id,
      product: this.product,
      priceId: element.id,
      pricing: element,
      weightUnit: element.weightUnit,
      weight: element.weight,
      name: this.product.name
    });
    this.close();
  }
}
