import {Pricing} from './pricing';
import {Benifit} from './benefit';

export class ProductDetail {
  index: number;
  minPrice: number;
  variations: number;
  id: string;
  name: string;
  categoryId: number;
  category: string;
  image: string;
  createdDate: string;
  prices: Pricing[];
  discount = 0;
  discountPercent = 0;
  benefits: Benifit[];
  description: string;
  weightUnit: number;
  weight: number;
  quantity: number;
}
