<div class="modal-header">
  <h5 class="modal-title mod-head text-center">
    Order Success
  </h5>
  <button (click)="close()" type="button" class="close">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-sm">
      <div class="text-center">
        <i class="fas fa fa-check-circle text-success fa-8x"></i>
        <br>
        <br>
        <span class="text-success font-weight-bold h5">{{message}}</span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row width-100">
    <div class="col-sm">
      <button class="btn btn-success btn-round btn-block" (click)="viewAll()">{{viewAllText}}</button>
    </div>
    <div class="col-sm">
      <button class="btn btn-success btn-round btn-block" (click)="createAnother()">{{createAnotherText}}</button>
    </div>
  </div>
</div>

