import {Injectable} from '@angular/core';
import {ConfigurationService} from './configuration.service';
import {HttpClient} from '@angular/common/http';
import {EncoderService} from './encoder.service';

@Injectable({
  providedIn: 'root'
})
export class EndpointFactoryService {

  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected encoderService: EncoderService) {

  }
}
