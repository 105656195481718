import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'benifitsAnimation'
})
export class BenifitsAnimation implements PipeTransform {
 transform(value: number): string {
    let caseValue = (value%3);
		
  switch (caseValue) {
      case 0: {
          return 'weedimg';
      }
      case 1: {
          return 'weed-ani';
      }
      case 2: {
          return 'weed-ani-2';
      }
}
 }
}