import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-custom-pin-control',
  templateUrl: './custom-pin-control.component.html',
  styleUrls: ['./custom-pin-control.component.scss']
})
export class CustomPinControlComponent implements OnInit, AfterViewInit {

  pinCount = 4;
  @Input() inputFormControl: FormControl = new FormControl();
  formArray: FormArray = new FormArray([]);
  formGroup: FormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    const controls: FormControl[] = [];
    for (let i = 0; i < this.pinCount; i++) {
      const c = new FormControl('', [Validators.required]);
      c.valueChanges.subscribe(p => {
        if (p) {
          const index = i;
          let pin = '';
          this.formArray.controls.forEach(item => {
            pin += item.value;
          });
          this.inputFormControl.setValue(pin);

          if (index < this.formArray.controls.length - 1) {
            const nativeElement = (this.formArray.controls[index + 1] as any).nativeElement;
            nativeElement.focus();
            const start = 0;
            const end = nativeElement.selectionEnd;
            nativeElement.setSelectionRange(start, end);
          }
        }
      });
      controls.push(c);
    }
    this.formArray = new FormArray(controls);
    this.formGroup = new FormGroup({
      pin: this.formArray
    });
  }

  ngAfterViewInit(): void {
  }
}
