<div *ngIf="step == 1">
    <div class="modal-header">
        <h5 class="modal-title mod-head text-center">
            Check Out
        </h5>
        <button (click)="close()" type="button" class="close">
      <span>&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm">
                <div class="pb-4">
                    <div class="form-check d-inline-block">
                        <input class="form-check-input radio-custom" name="pay cash" type="radio" checked value="option1">
                        <label class="radio-custom-label"></label>
                    </div>
                    <img class="img-fluid img-height" src="/assets/img/cash.png" alt="cash">
                    <span class="d-inline-block pl-2 position-relative position-top text-uppercase">pay with cash<br>{{netAmount | appCurrency}}</span>
                </div>
                <form [formGroup]="formGroup">
                    <app-custom-form-control [isDisplayError]='true' [fieldName]="'Email'" [placeholder]="'Enter Email'" [inputFormControl]="emailControl" [inputType]="'email'"></app-custom-form-control>
                    <!-- <app-custom-form-control [fieldName]="'Phone Number'" [isDisplayError]='true' [placeholder]="'Enter Phone Number'" [inputFormControl]="phoneControl" [inputType]="'phone'"></app-custom-form-control> -->
                    <app-custom-form-control [fieldName]="'First name'" [isDisplayError]='true' [placeholder]="'First Name'" [inputFormControl]="firstNameControl" [inputType]="'text'"></app-custom-form-control>
                    <app-custom-form-control [fieldName]="'Last name'" [isDisplayError]='true' [placeholder]="'Last Name'" [inputFormControl]="lastNameControl" [inputType]="'text'"></app-custom-form-control>
                    <!-- <app-custom-form-control [fieldName]="'Date of birth'" [isDisplayError]='true' [placeholder]="'Enter Date of birth'" [inputFormControl]="dobControl" [inputType]="'date'"></app-custom-form-control> -->
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row width-100">
            <div class="col-sm-12">
                <button (click)="verifyOtp()" [disabled]="!formGroup.valid" class="btn btn-success btn-lg btn-block rounded-pill text-center" data-toggle="modal">
          <!-- <span class="">Send OTP</span> -->
          <span class="">Save Order</span>
        </button>
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf="step == 2">
    <div class="modal-header">
        <h5 class="modal-title mod-head text-center">
            Verify OTP
        </h5>
        <button (click)="close()" type="button" class="close">
      <span>&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm">
                <form [formGroup]="formGroup">
                    <app-custom-pin-control [inputFormControl]="otpControl"></app-custom-pin-control>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row width-100">
            <div class="col-sm-12">
                <button (click)="verifyOtp()" [disabled]="canNotCheckOut" class="btn btn-success btn-lg btn-block text-center" data-toggle="modal">
          <span class="">Verify Otp</span>
        </button>
            </div>
        </div>
    </div>
</div> -->
