import {Pipe, PipeTransform} from '@angular/core';
import {PaymentType} from '../enums/payment-type.enum';

@Pipe({
  name: 'paymentType'
})
export class PaymentTypePipe implements PipeTransform {

  transform(value: number): string {
    switch (value as PaymentType) {
      case PaymentType.Cash:
        return '<span class="badge badge-success">CASH</span>';
    }
    return '<span class="badge badge-light">N/A</span>';
  }

}

