import {Component, OnInit} from '@angular/core';
import {Merchant} from '../../../../models/merchant';
import {MerchantDetail} from '../../../../models/merchantDetail';
import {ResponseModel} from '../../../../models/response.model';
import {ServiceService} from '../../service/service.service';
import {BasePaginationComponent} from '../../../../shared/base-pagination-component';
import {AlertService} from '../../../../services/alert.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {Category} from '../../../../models/category';
import {Product} from '../../../../models/product';
import {CartService} from '../../service/cart.service';
import {ActivatedRoute} from '@angular/router';
import { SubCategory } from '../../../../models/sub-category';
import { LogiCon } from 'src/app/constants/login-constants';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent  implements OnInit {
  subCategoryRow: SubCategory[] = [];
  selectedSubCategory: SubCategory = new SubCategory();
  sort = '';
  direction = 'desc';
  search = '';
  page = 1;
  pageSize = 20;
  totalPage = 0;
  noMerchant = false;
  noOfScrolled = 0;
  customOptions: OwlOptions = {
    rtl: false,
    loop: false,
    margin: 10,
    nav: true,
    dots: false,
    autoHeight: false,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 2
      },
      300: {
        items: 3
      },
      375: {
        items: 4
      },
      1000: {
        items: 5
      }
    }
  };


  constructor(private alertService: AlertService,
              private cartService: CartService,
              private activatedRoute: ActivatedRoute,
              private homeService: ServiceService, private modalService: NgbModal) {

  }

  merchantDetail: MerchantDetail = new MerchantDetail();
  rows: Category[] = [];
  products: Product[] = [];
  updateProduct : Product[] = [];
  merchant: Merchant = new Merchant();
  selectedCategory: Category = new Category();
  enableView = false;

  ngOnInit(): void {
    this.products = [];
    this.activatedRoute.params.subscribe(p => {
      this.merchantBasicInfo(p.id);
    });
  }
  merchantBasicInfo(id: string) {
    this.merchant.id = id;
    this.alertService.startLoadingMessage('Loading Page...');
    this.homeService.getMerchantProfile(this.merchant).subscribe((result) => {
      if (result.status === 200) {
        this.alertService.stopLoadingMessage();
        this.merchantDetail = result.body as MerchantDetail;
        this.cartService.setMerchantId(this.merchantDetail.profile.userId);
        this.cartService.setTax(this.merchantDetail.tax);
        this.enableView = true;
        this.rows = this.merchantDetail.categories as Category[];
        if (this.rows.length > 0) {
          this.selectedCategory = this.rows[0];
        }
        if (this.selectedCategory) {
          this.loadData(1, 20, this.selectedCategory, this.selectedSubCategory);
        } else {

          const res = result.body as ResponseModel;
          if (res.success === false ) {
            this.noMerchant = true;
          }
        }

      }
    });
    this.getSubCategory();

  }

  loadData(page = 1, pageSize = 20, category: Category = null, subCategory: SubCategory) {
    this.products = [];
    const subCategoryId =  subCategory.id === -1 || subCategory.id === undefined ? null : subCategory.id;
    this.alertService.startLoadingMessage();
    this.homeService.getProduct(
      this.merchant,
      category,
       page, pageSize, subCategoryId)
      .subscribe(results => this.onDataLoadSuccessful(results));
  }
  getSubCategory() {
    this.homeService.getSubCategory(this.merchant).subscribe(res => {
      this.subCategoryRow = res.data as SubCategory[];
      this.subCategoryRow.splice(0, 0, {
        id: -1,
        name: LogiCon.ALL
      });
      this.selectedSubCategory = {
        id: -1,
        name: LogiCon.ALL
      };

    });
  }
  onDataLoadSuccessful(result: ResponseModel) {
    this.alertService.stopLoadingMessage();
   this.products = [];
    const data = result.data as Product[];
    data.forEach((element, index) => {
      (element as any).index = ((this.page - 1) * 10) + index + 1;
      this.products.push(element);
    });
    console.log("Products : " , this.products)
    this.products.forEach(element=>{
      if(element.discountPercent > 0)
      {
        element.isDiscounted = true
        element.discountedPrice = this.getDiscountedPrice(element.minPrice , element.discountPercent)
      }
      else{
        element.isDiscounted = false
      }

    })
    console.log("Updated Products : " , this.products)
    this.totalPage = result.count;
  }

  getDiscountedPrice(price, discount)
  {
    var discounted_price = price - (price * discount / 100)
    return discounted_price;
  }
  onSelectCategoryChanged(category: Category) {
    this.products = [];
    this.page = 1;
    this.noOfScrolled = 0;
    this.loadData(1, 20, category, this.selectedSubCategory);
  }

  onScroll() {
    this.noOfScrolled++;
    if (this.noOfScrolled >= this.pageSize * this.page || this.noOfScrolled - 1 >= this.pageSize * this.page) {
      this.changePage(this.page + 1 );
    }

  }
  changePage(page: number) {
    this.page = page;
    this.loadData(page  , this.pageSize, this.selectedCategory, this.selectedSubCategory);
  }

  changeSubCategory(element: SubCategory) {
    this.selectedSubCategory = element;
    this.products = [];
    this.page = 1;
    this.noOfScrolled = 0;
    this.loadData(1, 20, this.selectedCategory, this.selectedSubCategory);

  }
}
