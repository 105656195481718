<div class="row align-items-xl-center align-items-lg-start">
  <div class="col-xl-auto col-xl-3 col-lg-3 text-web-kit">
    <div class="card p-xl-5 p-lg-5 p-md-4 p-4 card-own card-width">
      <div class="flag">
        <div class="flag-pattern">
          <app-image [src]='merchantDetail.profile.logo' [class]='"card-img-top img-fluid flag-wave"'></app-image>

        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-9 col-lg-9 mt-xl-3 mt-lg-5 my-3">
    <div class="col-xl-12 col-lg-12 col-md-12 text-own-center text-xs-left dis-pos">
      <img class="dis-img" src="/assets/img/Dispensary.png " alt="Name " />
      <span class="main-color Herbtech-h4 dis-h d-block">{{
        merchantDetail.profile.companyName
        }}</span>
      <span class="main-color Marijiuana-span font-mont-18">Weed , Marijiuana</span>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12 text-own-center text-xs-left py-2 tim-pos">
      <img class="time-size mr-2" src="/assets/img/time.png " alt="Time " />
      <span class="main-color font-mont-18 time-pos">{{
        merchantDetail.profile.timings
        }}</span>
    </div>

        <div class="col-xl-12 col-lg-12 col-md-12 text-own-center text-xs-left address-pos">
            <img class="loc-size mr-2" src="/assets/img/location.png " alt="Location " />
            <p class="loc-text main-color c-b font-mont-18 font-bold d-inline-block">{{ merchantDetail.profile.street }} ,{{ merchantDetail.profile.city
      }} {{ merchantDetail.profile.state
      }} {{ merchantDetail.profile.country }}</p
    >
  </div>
</div>
<div class="col-12 about-us m-own mb-xl-5 p-5-own p-2-own text-xs-center">
  <i class="far fa-user user-icon"></i>
  <h2 class="a-h font-mont-26 ml-md-2">About Us</h2>
  <span class="a-span font-mont-18">
    {{ merchantDetail.profile.aboutUs }}
  </span>
</div>
