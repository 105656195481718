import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './components/home/home.component';
import { SharedModule } from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductDetailInfoComponent } from './components/product-detail-info/product-detail-info.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HeaderComponent } from './components/header/header.component';
import { MerchantBasicInfoComponent } from './components/merchant-basic-info/merchant-basic-info.component';
import { CategorySliderComponent } from './components/category-slider/category-slider.component';
import { ProductInfoComponent } from './components/product-info/product-info.component';
import { CartComponent } from './components/cart/cart.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CartInfoDialogComponent } from './components/cart-info-dialog/cart-info-dialog.component';
import { CustomPipeModule } from '../../pipes/custom-pipe.module';
import { CartItemComponent } from './components/cart-item/cart-item.component';
import { CheckOutDialogComponent } from './components/check-out-dialog/check-out-dialog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [HomeComponent, ProductDetailInfoComponent,
    HeaderComponent, MerchantBasicInfoComponent, CategorySliderComponent,
    ProductInfoComponent, CartComponent, CartInfoDialogComponent, CartItemComponent,
    CheckOutDialogComponent,
  ],
  exports: [
    CartItemComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    CarouselModule,
    ReactiveFormsModule,
    CustomPipeModule,
    InfiniteScrollModule,
    FormsModule
  ]
})
export class HomeModule {
}
