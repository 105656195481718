import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'orderAnimationCss'
})
export class AnimationPipe implements PipeTransform {

  transform(value: number): string {
      let isEven = value % 2 ==0;
 
    switch (isEven) {
        case (true) : {
            return 'weed-ani-2';
        
        }
        case (false) : {
            return 'weed-ani';
            
        }

  }

}
}
