<app-header [merchantDetail]='merchantDetail' *ngIf="enableView"></app-header>
<div class="container pt-own">
    <app-merchant-basic-info [merchantDetail]="merchantDetail" *ngIf="enableView"></app-merchant-basic-info>
    <app-category-slider *ngIf="rows.length > 0" [selectedCategory]="selectedCategory" [categories]="rows" (onSelectChange)="onSelectCategoryChanged($event)"></app-category-slider>
    <!-- Sub categories Start -->
    <div class="mb-md-0 mb-4" *ngIf="products.length > 0">
        <ngb-accordion #acc="ngbAccordion">
            <ngb-panel>
                <ng-template ngbPanelTitle class="w-100">
                    Select Sub Category
                    <i class="fas fa-bars float-right"></i>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div (click)='changeSubCategory(subCate)' *ngFor='let subCate of subCategoryRow'>
                        <label class="radio f-14 text-capitalize mb-1">
                            {{subCate.name}}
                            <input [checked]='subCate.id == selectedSubCategory.id' class="radio-input" type="radio"  name="radio">
                            <span class="radio-checkmark"></span>
                        </label>
                        <hr>
                    </div>

                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <!-- Sub categories End -->
    <div class="row">
        <div class="col-xl-4 col-6 pr-xs-1" *ngFor="let element of products">
            <app-product-info infiniteScroll [product]="element" [merchant]="merchant" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"></app-product-info>
        </div>
    </div>
    <app-cart *ngIf="rows.length > 0" [merchantId]='merchant.id'></app-cart>
</div>

<app-footer *ngIf='enableView'></app-footer>
<div class="row" *ngIf='noMerchant'>
    <div class="row">


        <img class="col-md-3 col-3 ml-auto mr-auto" src='../../../../../assets/img/herbtech-sad.png'>


    </div>
    <div class="row">
        <div class="col-12">
            <h6 style="color:#30a749">
                OPPS! looks like there is no merchant available or the merchant has not activated his account yet.
            </h6>
        </div>
    </div>

</div>


<div class="text-center fadeIn" *ngIf="!enableView">
  <i class="fas fa-exclamation-circle f-34rem text-grid"></i>
  <h1 class="f-6rem my-2 text-grid text-capitalize font-weight-bold">
    merchant not found
  </h1>
</div>
