import {SearchBoxComponent} from './search-box/search-box.component';
import {FormErrorComponent} from './form-error/form-error.component';
import {NgModule} from '@angular/core';
import {SuccessDialogComponent} from './success-dialog/success-dialog.component';

import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {CardComponent} from './card/card.component';
import {ImageComponent} from './image/image.component';
import {CustomFormControlComponent} from './custom-form-control/custom-form-control.component';
import {CustomPinControlComponent} from './custom-pin-control/custom-pin-control.component';
import {CustomDirectiveModule} from '../directives/custom-directive.module';
import {BackButtonComponent} from './back-button/back-button.component';
import {FooterComponent} from './footer/footer.component';
import { SeeMoreComponent } from './see-more/see-more.component';


@NgModule({
  declarations: [SearchBoxComponent, FormErrorComponent, SuccessDialogComponent,
    CardComponent, ImageComponent,
    BackButtonComponent, FooterComponent,
    CustomFormControlComponent, CustomPinControlComponent, SeeMoreComponent],
    exports: [SearchBoxComponent, FormErrorComponent, SuccessDialogComponent,
        CardComponent,
        BackButtonComponent, FooterComponent,
        ImageComponent, CustomFormControlComponent, CustomPinControlComponent, SeeMoreComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CustomDirectiveModule,
  ]
})
export class SharedModule {
}
