<div *ngIf="enableView">
  <div class="modal-header">
    <button (click)="close()" type="button" class="close">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body modal-body-height overflow-y-overlay">
    <div class="row h-inherit">
      <div class="col-xl-6 col-lg-6 col-md-6 col-12">
        <div class="p-xl-3 my-xl-3 text-center">
          <div class="product-image mb-md-3 weedimg-2">
            <div class="image-height">
              <app-image [class]="'img-fluid m-w-h'" [src]="productDetail.image"></app-image>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-12">
        <div class="row">
          <div class="col-12">
            <h5 class="modal-title mod-head text-center text-capitalize">
              {{ productDetail.name }}
            </h5>
          </div>
          <div class="col-12">
            <div class="position-relative mt-3">
              <app-see-more [description]="productDetail.description"></app-see-more>
            </div>
          </div>
          <div class="col-12">
            <div class="position-relative mt-3">
              <h6 class="text-base mb-1 modal-font">
                Top reported strain effects
                <i class="fas fa-info-circle" data-toggle="Info" title="Info!"></i>
              </h6>
              <div class="row my-3">
                <div class="col-4" *ngFor="let element of productDetail.benefits">
                  <div class="product-image mb-md-3  text-center {{
                      element.index | benifitsAnimation
                    }}">
                    <app-image [class]="'img-fluid weed-height modal-height'" [src]="element.image"></app-image>
                    <h6 class="text-base mb-1 text-center modal-font">
                      {{ element.name }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-6">
            <div class="overflow-x-scroll mt-3 pb-2">
              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <span *ngFor="let element of productDetail.prices">
                  <button class="btn btn-outline sm-font-size modal-font active"
                    [class.onActive]="element.id == currentPricing.id" (click)="selectWeight(element)">
                    {{ element.weight }}{{ element.weightUnit }}
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-6 mb-3">
            <div class="position-relative mt-3 float-xs-right">
              <button (click)="decreaseQuantity()"
                class="sub btn btn-outline-success sm-font-size border-0 background-gray-green text-center modal-font px-2 br-25">
                <i class="cursor fa fa-minus"></i>
              </button>
              <input type="number" readonly [value]="quantityControl.value" class="sm-font-size mx-1 px-2 mod-input"
                style="margin-right: 1px; margin-left: 1px" />
              <button (click)="increaseQuantity()"
                class="add btn btn-outline-success sm-font-size border-0 background-gray-green text-center modal-font px-2 br-25">
                <i class="cursor fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="col-12">
            <div class="position-relative row">
              <div class="col-6 pt-1">
                <h6 class="text-base mb-1 text-success">Total Price:</h6>
              </div>
              <div class="col-6">
                <span class="text-base mb-1 float-right mod-money">
                  {{ totalPrice | appCurrency }}
                </span>

                <!-- <h6 class="text-base mb-1 float-right">$60.00</h6> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a (click)="addToCart()" class="btn btn-success btn-lg btn-block rounded-pill text-center">
      <span class="">Add To Cart</span>
    </a>
  </div>
</div>
