import {Component, OnInit, Input} from '@angular/core';
import {CartService} from '../../service/cart.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CartInfoDialogComponent} from '../cart-info-dialog/cart-info-dialog.component';
import {Order} from '../../../../models/order';
import {SuccessDialogComponent} from '../../../../shared/success-dialog/success-dialog.component';
import {NavigationService} from '../../../../services/navigation.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  @Input() merchantId: string;
  constructor(private cartService: CartService,
              private navigationService: NavigationService,
              private dialogService: NgbModal) {
  }

  ngOnInit(): void {
  }

  get total() {
    return this.cartService.getTotal();
  }

  showCartDialog() {
    this.navigationService.navigateByUrl('cart/' + this.merchantId);
    return;
    const modal = this.dialogService.open(CartInfoDialogComponent, {
      size: 'lg',
      centered: true
    });
    const instance = modal.componentInstance as CartInfoDialogComponent;
    instance.orderCompleted = order => {
      modal.close();
      this.showSuccessDialog(order);
    };
  }

  showSuccessDialog(order: Order) {
    this.cartService.clear();
    const modal = this.dialogService.open(SuccessDialogComponent, {
      size: 'lg',
      centered: true
    });
    const instance = modal.componentInstance as SuccessDialogComponent;
    instance.message = 'You have successfully placed Order.\nYou Order Id: ' + order.id;
    instance.createAnotherText = 'View Receipt';
    instance.viewAllText = 'Close';
    instance.viewAll = () => {
      modal.close();
    };
    instance.createAnother = () => {
      modal.close();
      this.navigationService.navigateByUrl('/receipt/' + order.id);
    };
  }
}
