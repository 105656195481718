import {Component, Input, OnInit} from '@angular/core';
import {CartItem} from '../../../../models/cartItem';
import {CartService} from '../../service/cart.service';
import {AlertService, DialogType} from '../../../../services/alert.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {

  @Input() cartItem: CartItem = new CartItem();

  constructor(private cartService: CartService, private alertService: AlertService) {
  }

  ngOnInit(): void {
  }

  increaseQuantity() {
    this.cartService.increaseQuantity(this.cartItem.productId, this.cartItem.priceId);
  }

  decreaseQuantity() {
    this.cartService.decreaseQuantity(this.cartItem.productId, this.cartItem.priceId);
  }

  removeFromCart() {
    this.alertService.showDialog('Do you want to remove this item?', DialogType.confirm, () => {
      this.alertService.closeDialog();
      this.cartService.removeItemFromCart(this.cartItem.productId, this.cartItem.priceId);
    });
  }
}
