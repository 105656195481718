export enum PaymentType {
  Cash = 0,
}

export enum OrderStatus {
  None,
  Pending,
  Completed,
  Cancelled
}
